import React from "react"

const AgendaTime = ({time, color}) => (
  <div className="font-weight-bold" style={{ color: `${color}` }}>{time}</div>
)

AgendaTime.defaultProps = {
  color: `#000`,
}

export default AgendaTime
