import React, { useContext } from "react"
import { MyAgendaContext } from "../providers/my-agenda";

const MyAgendaBtn = ({ session, date, sessionId }) => {

  const { state, dispatch } = useContext(MyAgendaContext);

  const style = {
    backgroundColor: `#fff`,
    borderColor: `#fff`,
    color: `#d23464`,
    textTransform: `uppercase`,
    borderRadius: `0`,
    padding: `.6rem 1rem`,
    fontWeight: `600`
  }

  return (
    (state.some(el => el.name === session)) ? <button className="btn btn-secondary btn-sm" style={style} onClick={() => dispatch({ type: 'remove', payload: { name: session, date: date, sessionId: sessionId}}) }>Unselect</button> : <button className="btn btn-primary btn-sm" style={style} onClick={() => dispatch({ type: 'add', payload: { name: session, date: date, sessionId: sessionId}}) }>Select</button>
  )
}

export default MyAgendaBtn