import React, { useReducer, createContext } from 'react'

export const MyAgendaContext = createContext([])

export const MyAgendaProvider = ({ children }) => {  
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
        case 'add':
            return [...state, action.payload];
        case 'remove':
            return state.filter((value) => { return value.name !== action.payload.name })
        case 'reset':
            return [];
        default:
            throw new Error();
    }
  }, []);

  return (
    <MyAgendaContext.Provider value={{ state, dispatch }}>
        {children}
    </MyAgendaContext.Provider>
  )
}