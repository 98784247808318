import PropTypes from "prop-types"
import React from "react"

const SectionTitle = ({ text }) => (
  <h2 style={{
    color: `#fff`,
    fontFamily: `Avenir`,
    fontWeight: `900`,
    fontSize: `2.5rem`,
    textAlign: `center`,
    margin: `3rem 0`
  }}>{text}</h2>
)

SectionTitle.propTypes = {
  text: PropTypes.string,
}

SectionTitle.defaultProps = {
  text: `Section Title`,
}

export default SectionTitle