import React from "react"

const AgendaTitle = ({title, color}) => (
  <div className="text-center font-weight-bold" style={{ color: `${color}` }}>{title}</div>
)

AgendaTitle.defaultProps = {
  color: `#929292`,
}

export default AgendaTitle
