import PropTypes from "prop-types"
import React from "react"

const EventCard = ({ img, title, color, children }) => (
  <div className="card" style={{
    border: 0,
    position: "relative",
    borderRadius: 0
  }}>
    <img src={img} class="card-img-top" alt={title}></img>
    <div class="card-body">
      <h5 class="card-title" style={{
        position: "absolute",
        top: 0,
        right: 0,
        padding: `1rem`,
        textTransform: `uppercase`,
        fontWeight: `bold`,
        color: `#fff`,
        backgroundColor: color
      }}>{title}</h5>
      {children}
    </div>
  </div>
)

EventCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
}

EventCard.defaultProps = {
  img: `Title`,
  title: `Title`,
  color: `#d23464`,
}

export default EventCard
