import React from "react"

const AgendaDescription = ({children}) => (
  <div className="container" style={{
    color: `#444`,
    fontSize: `14px`,
    maxWidth: `800px`
  }}>
    {children}
  </div>
)

AgendaDescription.defaultProps = {
}

export default AgendaDescription
