import React from "react"
import PropTypes from "prop-types"
import MyAgendaBtn from "./my-agenda-btn"

const AgendaDay = ({children, title, day, subtitle, color, description, date, sessionId, disabled}) => (
  <div className="text-center text-white">
    <div className="pt-4 pb-2" style={{ backgroundColor: `rgb(0,0,0,0.6)` }}>{title}<br /><span style={{ fontSize: `72px`, fontWeight: `bold` }}>{day}</span></div>
    <div className="text-uppercase py-3" style={{ backgroundColor: color }}>{subtitle}</div>

    <div className="bg-white p-3">
      {children}
    </div>
    {/* <div className="text-uppercase py-3" style={{ backgroundColor: color }} hidden={ disabled }><MyAgendaBtn session={description} date={date} sessionId={sessionId} ></MyAgendaBtn></div> */}
  </div>
)

AgendaDay.propTypes = {
  color: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
}

AgendaDay.defaultProps = {
  color: `#ff8c27`,
  description: `Description`,
  date: `Date`,
  sessionId: `Session ID`,
  disabled: false
}

export default AgendaDay
