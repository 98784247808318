import PropTypes, { object } from "prop-types"
import React, { useState, useContext } from "react"
import { MyAgendaContext } from "../providers/my-agenda";
import MyAgendaBtn from "./my-agenda-btn";

const MyAgenda = ({ text, maxHeight, minHeight }) => {

  const { state, dispatch } = useContext(MyAgendaContext);

  const [localstate, setState] = useState({
    visible: false,
    submitted: false
  });

  const toggleVisability = () => {
    setState({...localstate, visible: !localstate.visible});
  }

  const submitForm = (e) => {
    e.preventDefault();
    let myForm = document.getElementById('conference-registration')
    let formData = new FormData(myForm);

    let data = Object.fromEntries(formData);

    data.events = state
    data.eventString = state.map((i) => i.sessionId).toString();

    console.log(data);

    if(state.length === 0) return alert("You must select at least one session before registering. Please try again.");

    // send data to make.com webhook
    fetch('https://hook.eu1.make.com/gjsbwfabrwqgdmr1dx44yt4v6d8fsm6f', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(() => {
      setState({...localstate, submitted: !localstate.submitted});
      clearItems();
    }).catch((error) => alert(error));
  }

  const addItem = (name) => {
    dispatch({type: 'add', payload: { name: name }})
  }

  const clearItems = () => {
    dispatch({type: 'reset'});
  }

  return (
  <div>
    <div className="p-4" style={{
      backgroundColor: `#000`,
      position: `fixed`,
      bottom: `0%`,
      width: `100%`,
      zIndex: `10000`,
      height: ((localstate.visible) ? maxHeight : minHeight),
      fontFamily: `Din Pro`
    }}>
      <div className="d-flex justify-content-between" style={{
        height: minHeight,
        color: `#fff`
      }}>
        <div>My Agenda</div>
        <div>Selected <span className="badge badge-secondary">{state.length}</span></div>
        <div>
          <button onClick={toggleVisability} className="btn btn-secondary btn-sm">
            <span style={{textTransform: `uppercase`, fontWeight: `bold`}}>{!localstate.visible ? 'Open' : 'Close'}</span>
          </button>
        </div>
      </div>
      <div className="row" hidden={ localstate.submitted }>
        <div className="col">
          <p hidden={(!state.length) ? false : true } className="text-white">Please select from the conference the days you wish to attend.</p>
          <div style={{
            overflowY: `auto`,
            height: `365px`
          }}>
            <ul className="list-group" hidden={(state.length) ? false : true }>
              {state.map((object, i) => <li key={i} className="list-group-item"><div className="d-flex justify-content-between"><div><small>{object.date}</small><br />{object.name}</div><div><MyAgendaBtn session={object.name} /></div></div></li> )}
            </ul>
          </div>
          <div hidden={ state.length<6 }><small className="text-white">Scroll to see all of your selected workshops</small></div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-between">
            <h3 className="text-white">Registration Form</h3>
            <div><button className="btn btn-warning btn-sm" style={{textTransform: `uppercase`, fontWeight: `bold`}} onClick={clearItems}>Reset Selection</button></div>
          </div>
          <p className="text-white">Please make your selections and complete this form to register for the days you wish to attend.</p>
          <form id="conference-registration" onSubmit={submitForm}>
            <div className="form-group">
              <input type="email" className="form-control" id="email_address" name="email" placeholder="Work Email Address" required />
              {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div className="form-row mb-3">
              <div className="col">
                <input type="text" className="form-control" id="first_name" name="first_name" placeholder="First Name" required />
              </div>
              <div className="col">
                <input type="text" className="form-control" id="last_name" name="last_name" placeholder="Last Name" required />
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col">
                <input type="text" className="form-control" id="organisation" name="organisation" placeholder="Organisation" autoComplete="organization" required />
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
              <div className="col">
                <input type="text" className="form-control" id="job_title" name="job_title" placeholder="Job Title" autoComplete="organization-title" required />
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col">
                <input type="phone" className="form-control" id="phone" name="phone" placeholder="Phone" required />
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
              <div className="col">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="privacy-policy" />
                  <label className="form-check-label" for="privacy-policy" style={{ fontSize: `12px`, color: `#fff`}}>
                    I acknowledge that I have read, understood and accepted the organiser's <a href="https://www.govnewsdirect.com/privacy-policy" target="_blank" className="text-white" style={{ textDecoration: `underline`}}>Privacy Policy</a>
                  </label>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary" style={{
              backgroundColor: `#fff`,
              borderColor: `#fff`,
              color: `#d23464`,
              textTransform: `uppercase`,
              borderRadius: `0`,
              padding: `.6rem 1rem`,
              fontWeight: `600`
            }}>Register</button>
          </form>
        </div>
      </div>
      <div className="text-white" hidden={ !localstate.submitted }>
        <p>Thank you for registering for Smarter Working Live.</p>
        <p><strong>What happens next?</strong></p>
        <p>You will shortly receive an email confirming your registration. Within 48 hours following, you will receive joining details for the different dates that you selected when registering. If you fail to receive these, please email our team on events@govnewsdirect.com</p>
      </div>
    </div>
    <div style={{
      height: ((localstate.visible) ? maxHeight : minHeight)
    }} />
  </div>  
  )
}

MyAgenda.propTypes = {
  text: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
}

MyAgenda.defaultProps = {
  text: `Section Title`,
  maxHeight: `500px`,
  minHeight: `80px`
}

export default MyAgenda