import React from "react"

const AgendaItem = ({children, color}) => (
  <div className="shadow-sm p-3 mb-3 rounded text-white" style={{ backgroundColor: `${color}` }}>
    {children}
  </div>
)

AgendaItem.defaultProps = {
  color: `#fff`,
}

export default AgendaItem
